@import '../../style/themes/index';
@import '../../style/mixins/index';

@collapse-prefix-cls: ~'@{ant-prefix}-collapse';

.@{collapse-prefix-cls} {
  .reset-component;

  background-color: @collapse-header-bg;
  border: @border-width-base @border-style-base @border-color-base;
  border-bottom: 0;
  border-radius: @collapse-panel-border-radius;

  & > &-item {
    border-bottom: @border-width-base @border-style-base @border-color-base;

    &:last-child {
      &,
      & > .@{collapse-prefix-cls}-header {
        border-radius: 0 0 @collapse-panel-border-radius @collapse-panel-border-radius;
      }
    }

    > .@{collapse-prefix-cls}-header {
      position: relative;
      padding: @collapse-header-padding;
      padding-left: @collapse-header-padding-extra;
      color: @heading-color;
      line-height: @line-height-base;
      cursor: pointer;
      transition: all 0.3s;
      .clearfix;

      .@{collapse-prefix-cls}-arrow {
        .iconfont-mixin();

        position: absolute;
        top: 50%;
        left: @collapse-header-arrow-left;
        display: inline-block;
        font-size: @font-size-sm;
        transform: translateY(-50%);

        & svg {
          transition: transform 0.24s;
        }
      }

      .@{collapse-prefix-cls}-extra {
        float: right;
      }

      &:focus {
        outline: none;
      }
    }

    &.@{collapse-prefix-cls}-no-arrow {
      > .@{collapse-prefix-cls}-header {
        padding-left: 12px;
      }
    }
  }

  // Expand Icon right
  &-icon-position-right {
    & > .@{collapse-prefix-cls}-item {
      > .@{collapse-prefix-cls}-header {
        padding: @collapse-header-padding;
        padding-right: @collapse-header-padding-extra;

        .@{collapse-prefix-cls}-arrow {
          right: @padding-md;
          left: auto;
        }
      }
    }
  }

  &-anim-active {
    transition: height 0.2s @ease-out;
  }

  &-content {
    overflow: hidden;
    color: @text-color;
    background-color: @collapse-content-bg;
    border-top: @border-width-base @border-style-base @border-color-base;

    & > &-box {
      padding: @collapse-content-padding;
    }

    &-inactive {
      display: none;
    }
  }

  &-item:last-child {
    > .@{collapse-prefix-cls}-content {
      border-radius: 0 0 @collapse-panel-border-radius @collapse-panel-border-radius;
    }
  }

  &-borderless {
    background-color: @collapse-header-bg;
    border: 0;
  }

  &-borderless > &-item {
    border-bottom: 1px solid @border-color-base;
  }

  &-borderless > &-item:last-child,
  &-borderless > &-item:last-child &-header {
    border-radius: 0;
  }

  &-borderless > &-item > &-content {
    background-color: transparent;
    border-top: 0;
  }

  &-borderless > &-item > &-content > &-content-box {
    padding-top: 4px;
  }

  &-ghost {
    background-color: transparent;
    border: 0;
    > .@{collapse-prefix-cls}-item {
      border-bottom: 0;
      > .@{collapse-prefix-cls}-content {
        background-color: transparent;
        border-top: 0;
        > .@{collapse-prefix-cls}-content-box {
          padding-top: 12px;
          padding-bottom: 12px;
        }
      }
    }
  }

  & &-item-disabled > &-header {
    &,
    & > .arrow {
      color: @disabled-color;
      cursor: not-allowed;
    }
  }
}

@import './rtl';

@white: #fff;@black: #000;@primary: #a5302e;@color-1: #333333;@color-2: fade(@color-1, 80%);@color-3: fade(@color-1, 50%);@color-4: #ededed;@color-5: #e5e5e5;@color-6: #b2b2b2;@color-7: fade(@primary, 60%);@color-8: #f3dede;@color-9: #f44336;@font-family-primary: Product Sans;@background-color-primary: @primary;@background-color-secondary: @color-4;@background-color-disabled: @color-7;@border-color: @color-5;@border-color-active: @color-6;@text-color-primary: @color-1;@text-color-secondary: @color-2;@text-color-disabled: @color-3;@btn-primary-disabled-color: @white;@btn-primary-disabled-bg: @color-6;@input-border-color: @color-5;@input-border-focus-color: @color-6;@placeholder-color: @color-6;@scrollbar-thumb-color: @color-6;@overlay-mask-color: rgba(0, 0, 0, 0.7);@btn-primary-background-color: @primary;@btn-primary-color: @white;@btn-secondary-background-color: @color-8;@btn-secondary-color: @primary;@btn-secondary-active-background-color: @btn-primary-background-color;@btn-secondary-active-color: @btn-primary-color;@hover-effect-card: fade(@background-color-secondary, 50%);@input-color-active: @primary;@input-color-hover: @black;@input-color-default: @color-3;@input-color-background: @white;@input-color-error: @color-9;@font-family: @font-family-primary;@primary-color: @background-color-primary;@body-background: @background-color-secondary;@text-color: @text-color-primary;@link-color: @background-color-primary;@heading-color: @text-color-primary;@tabs-hover-color: @link-color;@border-color-base: @border-color;@border-color-split: @border-color;@border-color-inverse: @white;